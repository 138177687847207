<template>
    <layout class="wrapper infomation-detail">
        <!-- 面包屑 -->
        <div class="container">
            <div class="infomation-mobile-bread mt15 flex x-left">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/special-ma/video-list/'+$route.params.id }">相关视频</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '' }">视频列表</router-link>
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="video-content flex">
                <div class="video-list flex-item">
                    <div class="search-box mb20">
                        <a-input-group class="select-list flex x-left">
                            <a-select class="select-item" default-value="请选择分类" allowClear v-model="type" @change="handleTypeChange">
                                <a-select-option class="row-1" :key="index" v-for="(item,index) in typeList" :value="item.id">{{ item.type_name }}</a-select-option>
                            </a-select>
                            <a-input-search allowClear class="search" v-model="search_name" enter-button="搜索" placeholder="请输入题目/作者/单位" @search="handleSearch"></a-input-search>
                        </a-input-group>
                    </div>
                    <a-spin style="min-height:300px" :spinning="loading" tip="Loading...">
                        <div class="flex x-left flex-wrap">
                            <course-item width="240px" height="130px" :data-list="videoList" type="ma"></course-item>
                        </div>
                        <a-empty v-if="!videoList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt30" v-if="videoCount > 0">
                        <a-pagination :total="videoCount" v-model:current="videoParams.page" :page-size="videoParams.PageSize" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                    </div>
                </div>
                <div class="video-right">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import courseItem from '@/components/courser-list-item';
import { jsonFormat } from '@/utils/jsonFormat';
import { committee_id } from '@/config';
export default {
    name: "SpecialMaVideoList",
    components: {
        layout,
        layoutRight,
        courseItem,
    },
    data() {
        return {
            loading: true,
            videoList:[], //视频列表
            typeList:[], //自定义分类列表
            search_name:'', //搜索内容
            videoParams:{
                page: 1,
                PageSize: 15,
                no_count: true,
                topic_id: this.$route.params.id, 
                topic_committee_id: committee_id,
                topic_type_id:'',
            },
            type:'请选择分类',
            videoCount: 0,
            layoutRightConfig: {
                // 相关资讯
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关资讯', // 标题
                    moreLink:'/special-ma/infomation-list/'+this.$route.params.id,
                    detailLink:'/special-ma/infomation-detail/'+this.$route.params.id,
                    data: {
                        page: 1,
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id,
                        
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        page: 1,
                        pageSize: 6,
                        topic_id: this.$route.params.id, 
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                // 相关视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
            }
        }
    },
    computed: {
        
    },
    methods: {
        // 视频自定义分类
        getEnum(){
            this.request.post('TopicEnum',{topic_id:this.$route.params.id}).then(res=>{
                this.typeList = res.data
            })
        },
        // 获取视频列表
        getVideoList(){
            this.loading = true
            this.request.get('GetVideoListNew', this.videoParams).then(res => {
                // 视频列表
                let videoList = (res.data || {}).list || [];
                let videoListField = {
                    img: 'default_pic_url',
                    name: 'author',
                    org: 'org_cnname',
                    
                }
                this.videoCount = parseInt(res.data.count);
                this.videoList = jsonFormat(videoList, videoListField)

                this.loading = false;
            }).catch(err => {
                this.loading = false;
            })
        },
        // 分页
        handlePageChangeEvent(page){
            window.scrollTo(0,0);
            this.videoList.page = page
            this.getVideoList()
        },
        // 自定义分类切换
        handleTypeChange(value){
            this.videoParams.topic_type_id = value
            if(!value){
                this.videoParams.topic_type_id = ''
                this.type = '请选择分类'
            }
        },
        // 搜索按钮回调
        handleSearch(search_name) {
            this.videoParams.page = 1
            this.videoParams.title = search_name || ''
            this.getVideoList()
        },
    },
    // 缓存页面二次进入的钩子函数  动态参数更新时开启
    // activated() {
    //         conosle.log(111);
    //     this.getParams();
    // },
    created() {
        this.getEnum()
        this.getVideoList()
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
}
.video-content {
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 20px;
}

.video-list {
    width: calc(66.66% - 30px);
    margin-right: 30px;
    .mh {
        min-height: 500px;
    }
    .search-box{
        width: 400px;
        height: 40px;
        /deep/.ant-input{
            height: 40px;
            border-radius: 0;
        }
        /deep/.ant-btn{
            height: 40px;
            border: 0;
            background: @theme;
        }
        /deep/.ant-select-selection{
            width: 110px;
            height: 40px;
            border-radius: 5px 0 0 5px;
            border-right-width: 0;
        }
        /deep/.ant-select-selection__rendered{
            height: 40px;
            .ant-select-selection-selected-value{
                height: 40px;
                line-height: 40px;
            }
            
        }
    }
}
.video-right {
    width: 33.33%;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
@media screen and (max-width: 768px) {
    .container{
        padding: 0 10px;
        .infomation-mobile-bread{
            display: none;
        }
    }
    .video-right {
        padding-top: 20px;
        border-radius: 0;
        width: 100%;
    }
    .video-list{
        border-radius: 0;
        width: 100%;
        /deep/.ant-spin-nested-loading{
            min-height: 0!important;
        }
        .search-box{
            width: 100%;
        }
    }
    .video-content {
        border-radius: 0;
        display: block;
        width: 100%;
        margin-top: 10px;
    }
}
</style>